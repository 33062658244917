import React from 'react'
import {DESCRIPTIONS} from '../../helpers/constants'
import Layout from '../../components/nigeria/layout'
import {Link, Trans} from "gatsby-plugin-react-i18next";
import SpecialBg from "../../images/nigeria/special-bg.png";
import styled from 'styled-components';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';

const BBNTermsAndConditions = () => {
    return (
        <Layout pageTitle="BBN Terms and conditions | Moniepoint MFB">
            <Helmet>
                <link rel="alternate" href="https://moniepoint.com/ng/privacy-policy" hreflang="x-default"/>
                <link rel="alternate" href="https://moniepoint.com/ng/privacy-policy" hreflang="en-ng"/>
            </Helmet>
            <GetStartedContainer>
                <div className="bg-mask"></div>
                <div className="content">
                    <div className="get-started-section">
                        <h2 className={`title`}>
                            <Trans>BBN CUSTOMER GAMES TERMS AND CONDITIONS</Trans>
                        </h2>
                    </div>
                </div>
            </GetStartedContainer>
            <ContentContainer>
                <div className="content">
                    <div>
                        <div className="pp-list-outer">
                            <p className="pp-list-copy top">
                                These Terms of Use ("Agreement") govern your participation in the Big Brother Naija
                                (BBN) Customer Games (“the Games”) on the Moniepoint Personal Banking Application
                                (“Moniepoint App”) owned by Moniepoint Microfinance Bank Limited, including its parents,
                                subsidiaries or other affiliated companies (“the Organizer") during the Big Brother
                                Naija 2023 Show.

                                In this Agreement, “the Participant” or “you” refers to the party that accepts this
                                Agreement.

                                By engaging in the Games, you agree to abide by these Agreement and any specific rules
                                or guidelines provided by Moniepoint. These Agreement outline important rules, rewards
                                and your responsibilities as a participant, please read them carefully before accepting.

                            </p>


                        </div>


                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Eligibility of Participants</h1>
                            <p className="pp-list-copy">
                                You must meet the following requirements to be eligible to participate in the Games:

                                <ul className="pp-list inner">
                                    <li>Download the Moniepoint App {}</li>
                                    <li>Create an account and register your information. While registering, you will
                                        provide us with certain information and we may seek to verify your information
                                        (by ourselves or through third parties), after which we will approve your
                                        account unless we deem otherwise in our sole discretion. You give us permission
                                        to carry out all necessary checks (by ourselves or through third parties) to
                                        verify any information that you have provided
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Account Creation and Administration</h1>
                            <p className="pp-list-copy">
                                <ul className="pp-list inner">
                                    <li>You are eligible to do so in accordance with the applicable laws, rules, and
                                        regulations, and you are of legal age and meet any specific eligibility criteria
                                        set forth for each individual Game.
                                    </li>
                                    <li>
                                        You warrant that all information provided during participation in the Games,
                                        including registration details and account information, is true, accurate, and
                                        up-to-date. Any inaccuracies or discrepancies may lead to disqualification from
                                        the Games.
                                    </li>
                                    <li>
                                        You will participate in the Games fairly and in accordance with the specified
                                        rules and guidelines. Any attempt to manipulate or tamper with the Games, use
                                        unfair practices, or engage in fraudulent activities will result in immediate
                                        disqualification.
                                    </li>
                                    <li>
                                        You will be given a name and password upon creation of your account. You are
                                        required to keep your account information and login credentials including
                                        username and password, secure at all times. You are responsible for all
                                        transactions under your account. If you suspect unauthorized access, notify
                                        Moniepoint immediately.
                                    </li>
                                    <li>
                                        Moniepoint may suspend or terminate your account for breach of this Agreement,
                                        fraud or suspicious activity. Upon termination, any valid undisputed account
                                        balance will be paid to you.

                                    </li>
                                    <li>
                                        You must not misuse the Moniepoint App by intentionally introducing viruses or
                                        other harmful material. Unauthorized access to the Moniepoint App is strictly
                                        prohibited. Any information or data accessed through the Moniepoint App is for
                                        personal use only. Distribution or commercial exploitation of such information
                                        is not allowed. Participants shall not use automated systems or software to copy
                                        or extract the information for any purposes.
                                    </li>

                                </ul>
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Game Period and Leaderboard</h1>
                            <p className="pp-list-copy">
                                The Games will take place from [Start Date] to [End Date]. During this period,
                                Participants can view the leaderboard within the App to see their rank and compete for
                                the top position.
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Rules & Procedures for the Games</h1>
                            <p className="pp-list-copy">
                                <ul className="pp-list inner">
                                    <li><b className={'w-full'}>Coins and Points:</b><br/>
                                        A. Customers earn coins by completing various actions or tasks, with 1 coin
                                        equivalent to 1 point.
                                        B. Coins are depleted upon each game play, while points accumulate based on
                                        completed actions and determine Participant rankings.
                                        C. Coins are used to play the games during the Games.
                                        Coins are depleted on game plays. While points are accumulated based on actions
                                        completed and would be used in ranking each Participant based on their tasks
                                        completed.
                                    </li>
                                    <li>
                                        <b>Points Earning:</b><br/>
                                        Participants can earn points through the following actions or tasks:

                                    </li>
                                    <li>
                                        <b>Grand Prize:</b><br/>
                                        The Participant with the highest number of points at the end of the game period
                                        will top the leaderboard and be awarded the grand prize
                                        Games will be changed every week

                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">General Rules & Policies</h1>
                            <p className="pp-list-copy">
                                <ul className="pp-list inner numbered">
                                    <li>
                                        You warrant that you will respect and not infringe upon any intellectual
                                        property rights, including copyrights, trademarks, and any other proprietary
                                        rights related to the Games, the App and the Organizer. You acknowledge that all
                                        intellectual property rights remain the property of the Organizer or its
                                        licensors.
                                    </li>
                                    <li>
                                        The Organizer does not guarantee continuous availability and functionality of
                                        the Moniepoint App. It reserves the right to modify, suspend or withdraw any
                                        aspect or feature of the Moniepoint App and/or the Games without prior notice
                                    </li>
                                    <li>
                                        You may use the Moniepoint App solely for personal and non-commercial purposes.
                                        Reproduction of the App or any part of it in any form or creation of links
                                        suggesting association, approval, sponsorship or endorsement by the Organizer
                                        without our written consent is strictly prohibited.
                                    </li>
                                    <li>
                                        If the Organizer reasonably believes you have breached this Agreement or engaged
                                        in prohibited behavior, your account may be suspended for up to ninety (90)
                                        days. If your account is terminated, you may not be able to register a new
                                        account with the Organizer.
                                    </li>
                                    <li>
                                        The Participant is responsible for ensuring access to the Games on the
                                        Moniepoint App. The Organizer cannot guarantee compatibility with all hardware
                                        or software used by the Participant.
                                    </li>
                                    <li>
                                        Participants must be aware of their local laws regarding access to the
                                        Moniepoint App. Any usage of the Moniepoint App from outside Nigeria must comply
                                        with foreign jurisdiction laws and exchange control regulations and the
                                        Organizer is not responsible for remitting funds to foreign accounts.
                                    </li>
                                    <li>Any attempt to manipulate the outcome of the Games will result in automatic
                                        disqualification of the Participant.
                                    </li>
                                    <li>The Organizer gives no warranty or guarantee regarding a Participant's success.
                                    </li>
                                </ul>
                            </p>
                        </div>


                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Indemnity</h1>
                            <p className="pp-list-copy">
                                The Participant shall indemnify and hold the Organizer harmless from and against all
                                actions, proceedings, costs, claims, demands, charges, expenses (including legal
                                expenses),
                                liabilities, fines, levies, losses and damages, whether arising in tort, contract or
                                common
                                law, which the Organizer may suffer or incur to the extent arising out of or in
                                consequence
                                of or in connection with:
                                <ul className="pp-list inner numbered">

                                    <li>
                                        any claim brought against the Organizer arising from any aspect of this
                                        Agreement.
                                    </li>
                                    <li>
                                        the protection of the Organizer’s interest in connection with any aspect of this
                                        Agreement (including the cost of any third parties nominated by the Organizer or
                                        instructed by the Organizer for this purpose).

                                    </li>
                                    <li>
                                        a breach by the Participant of any of the provisions of this Agreement.

                                    </li>
                                    <li>
                                        any transaction (including a transaction which is subsequently discovered to be
                                        fraudulent or illegal).

                                    </li>
                                    <li>
                                        any regulatory fine or sanction imposed on the Organizer as a result of the
                                        Participant’s breach of this Agreement or any applicable laws in connection with
                                        the use of the Moniepoint App.

                                    </li>
                                    <li>
                                        respect to any negligent act or omission by, or willful misconduct of the
                                        Participant.

                                    </li>

                                </ul>
                            </p>
                        </div>

                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Personal Data Protection</h1>
                            <p className="pp-list-copy">
                                Participants hereby agree that personal data supplied when participating in the Games or
                                creating the account may be used by the Organizer to monitor participation and to
                                evaluate
                                the Games for subsequent improvements.
                                By participating in the Games, the Participants expressly agrees that the Organizer
                                shall
                                process personal data, which they supply, for the purposes of Games and also for the
                                purpose
                                of publicizing the Games and the winners. It is hereby specified that the Organizer
                                guarantees that the sharing with third parties of personal data supplied as a result of
                                participation in the Games, (including through television, the press, leaflets and other
                                means and formats of communication) shall be made only with respect to the winners and
                                will
                                only indicate the given names and surnames of the winners, city of residence, their
                                images
                                and voices (contained in photographic records, audio, video and other types of records,
                                made
                                with the purposes of publicity connected to the Games). The winning Participants give,
                                through their participation in the Games, express consent for the use of their personal
                                data.
                                In accordance with local laws and regulations, the personal data collected within the
                                scope
                                of this Games will be treated as private and confidential. It shall not be disclosed to
                                other individuals or organizations, except in the event of legislation to the contrary
                                or
                                where the Participants themselves authorize such disclosure. The personal data will be
                                stored on servers with access controlled on a need-to-know basis and limited by
                                passwords.
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Amendments</h1>
                            <p className="pp-list-copy">
                                The Organizer reserves the right to alter, suspend or cancel the Games or update this
                                Agreement at any time to comply, without prior notice to the Participants. The updated
                                version will be published on the Moniepoint App and it is your responsibility to review
                                and
                                understand the changes. Continued usage will be considered as acceptance of the updated
                                Agreement.
                                No claim in respect of these Agreement or any alteration thereof is enforceable against
                                the
                                Organizer following the moment at which it becomes available on the Moniepoint App.
                                In the event that the Games is suspended prior to the period designated for its
                                conclusion,
                                the Organizer shall award the Prizes corresponding to the winners selected up to the
                                date of
                                the suspension of the Games, in accordance with the present Agreement.
                            </p>

                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Governing Law and Dispute Resolution</h1>
                            <p className="pp-list-copy">
                                Any dispute arising from or connected to this Agreement shall be subject to the
                                exclusive
                                jurisdiction of the courts that have the requisite jurisdiction to entertain the dispute
                                and
                                competent Nigerian authorities and shall be governed in accordance with the Laws of the
                                Federal Republic of Nigeria.
                                If a dispute arises between the Organizer and the Participant in connection with the
                                interpretation, implementation or operation of this which cannot be resolved amicably by
                                them, the dispute shall be referred to mediation or conciliation under the Lagos State
                                Multidoor Court House.
                                The provisions of this clause constitute an irrevocable consent to any proceedings in
                                terms
                                hereof and no party shall be entitled to withdraw there from or claim at any such
                                proceedings that it is not bound by those provisions; and are severable from the rest of
                                this Agreement and shall remain in effect despite the invalidity of this Agreement for
                                any
                                reason.
                            </p>
                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Exclusion of Liability</h1>
                            <p className="pp-list-copy"> The access to and use of the Games is at the Participant’s sole
                                discretion and risk. The
                                Organizer shall not be liable for any attempts by the participant to access the Games by
                                methods, means or ways not intended by the organizer or provided in this Agreement.
                                The Organizer makes no promises or warranties regarding the Games and hereby excludes
                                (to
                                the extent permitted by law) all implied warranties in respect of the same.
                            </p>

                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Intellectual Property Rights</h1>
                            <p className="pp-list-copy"> The Moniepoint App, Games and its features is the sole property
                                of the Organizer. No license
                                is granted to Participants, except for personal use in accordance with this Agreement.

                                All website design, text, graphics, music, sound, photographs, video, the selection and
                                arrangement thereof, software compilations, underlying source code, software and all
                                other
                                material forming part of the Moniepoint App and Games are either owned by the Organizer
                                or
                                used under license from third party rights owners. In the event that any material
                                comprised
                                within the Moniepoint App may be downloaded or printed then such material may be
                                downloaded
                                to a single device only (and hard copies may be printed) solely for the Participants’
                                personal use (non-commercial).

                                Under no circumstances shall the use of the Moniepoint App grant to the participant any
                                interest in any intellectual property rights (for example copyright, know-how or
                                trademarks)
                                owned by the Organizer or by any third party whatsoever.
                                No rights whatsoever are granted to use or reproduce any trademarks or logos, which
                                appear
                                on the Moniepoint App except as specifically, permitted in accordance with this
                                Agreement.
                                The Participant shall not allow any other person to copy, store, publish, rent, license,
                                sell, distribute, alter, add to, delete, remove or tamper with the Moniepoint App or any
                                part of it in any way or directly or indirectly disrupt or interfere (or attempt to
                                disrupt
                                or interfere) with or alter the Moniepoint App, other than in the course of viewing or
                                using
                                the Moniepoint App in accordance with this Agreement.
                                All intellectual property rights in the name of the Organizer, the logos, designs,
                                trademarks and other distinctive brand features of the Organizer and any content
                                provided by
                                the Organizer or any third party for inclusion on the Moniepoint App vest in the
                                Organizer
                                or the applicable third party. The Participant agrees not to display or use such logos,
                                designs, trademarks and other distinctive brand features in any manner without the
                                Organizer’s prior written consent.
                            </p>

                        </div>
                        <div className="pp-list-outer">
                            <h1 className="pp-list-header">Severability</h1>
                            <p className="pp-list-copy">
                                If any of the terms of this Agreement is determined by any competent authority to be
                                invalid, unlawful or unenforceable to any extent, such term, condition or provision will
                                to
                                that extent be severed from the remaining terms, conditions and provisions which will
                                continue to be valid to the fullest extent permitted by law.
                                In such cases, the part deemed invalid or unenforceable shall be amended in a manner
                                consistent with the applicable law to reflect, as closely as possible, the Organizer's
                                original intent.
                                The Organizer reserves the right not to enter into written negotiations or otherwise
                                contact
                                with the Participants other than in accordance with this Agreement or in accordance with
                                the
                                requirements of the current legislation of the Federal Republic of Nigeria.
                            </p>
                        </div>

                    </div>
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default BBNTermsAndConditions


export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const GetStartedContainer = styled.section`
  background: rgba(3, 87, 238, 1);
  background-size: fill;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  color: white;
  position: relative;

  .bg-mask {
    background: url(${SpecialBg});
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
    opacity: 0.4;
  }

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }

    .get-started-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Founders Grotesk";
        font-size: 58px;
        font-weight: 500;
        line-height: 66px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 657px;
        margin-bottom: 8px;
      }

      .description {
        max-width: 623px;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }

      .get-started-button {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
        margin: 0 auto;
        padding: 16px 24px;
        border: none;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .content {
      padding: 81px 30px 98px;

      .get-started-section {
        .title {
          font-size: 32px;
          line-height: 35.04px;
          margin-bottom: 16px;
        }

        .description {
          font-size: 16px;
          line-height: 26px;
          text-align: center;
        }
      }
    }
  }
`;

const ContentContainer = styled.section`
  .content {
    max-width: 1440px;
    margin: auto;
    padding: 81px 151px 101px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 81px 60px 101px;
    }


    .pp-list {
      padding-left: 0;

      &.inner {
        margin-top: 8px;
        padding-left: 30px;
        list-style-position: inside;
      }

      ul {
        list-style-type: disc;
      }

      &.sub {
        list-style-type: circle;
      }
    }

    .pp-list-outer {
      margin-bottom: 40px;
      list-style-position: inside;

      &::marker {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .pp-list-header {
      font-family: "Founders Grotesk";
      font-style: normal;
      font-weight: bolder;
      font-size: 32px;
      line-height: 38px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      display: inline-block;
      line-height: 1.5;

      @media only screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .pp-list-copy {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
      opacity: 0.8;
      margin-bottom: 12px;

      &.top {
        margin-top: 10px;
      }

      b {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .pp-list-sub {
      padding-left: 0;
      counter-reset: item;
    }

    .pp-list-header {
      font-family: "Founders Grotesk";
      font-weight: 500;
      font-size: 24px;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
      display: inline-block;

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    li > b {
      color: rgba(0, 0, 0, 1);
    }

    .pp-list-outer-sub {
      margin-bottom: 16px;

      ul ul {
        li {
          list-style-type: circle;
        }
      }
    }

    ol > li {
      counter-increment: item;
    }

    ol.pp-list-sub > li {
      display: block;
    }

    ol.pp-list-sub > li:before {
      font-size: 24px;
      font-weight: 500;
      content: counters(item, ".") ". ";

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .pp-table {
      margin: 16px auto;
      border: 1px solid;

      th, td {
        border: 1px solid;
        padding: 6px;
        font-size: 14px;
      }
    }


    .image-container {
      display: flex;
      gap: 16px;

      .pp-image {
        width: 100%;
        height: 100%;
        max-width: 512px;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 30px;
    }
  }
`;